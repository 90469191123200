import { useInsertionEffect } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';

import { ConditionalWrapper } from '@/hoc';
import { NextLink, Picture, Text, Video } from '@/atoms';
import Button from '@/molecules/button';

import { containerTransformer } from '@/lib/graphql/transformers';
import { removeBaseURL } from '@/lib/utils';

import type { TypePictureProps } from '@/atoms/picture';
import type { TypeImageWordpress } from '@/components/types';
import type { ButtonProps } from '@/molecules/button';
import type { ElementType } from 'react';

const YoutubeVideo = dynamic(() => import('@/molecules/youtube-video'), {
  ssr: false,
});

type TypeVideo =
  | {
      videoType: 'youtube';
      youtubeVideoId: string;
    }
  | {
      videoType: 'wordpress';
      wordpressVideo: {
        mimeType: string;
        video: { mediaItemUrl: string };
      }[];
    };

type TypeFlexibleContent =
  | {
      type: 'pretitle';
      fieldGroupName: string;
      color: string | null;
      pretitle: string;
    }
  | {
      type: 'title';
      fieldGroupName: string;
      color: string | null;
      desktopSize: number | null;
      mobileSize: number | null;
      headingType: ElementType;
      title: string;
    }
  | {
      type: 'description';
      fieldGroupName: string;
      color: string | null;
      allUppercase: boolean;
      desktopSize: number | null;
      mobileSize: number | null;
      description: string;
    }
  | {
      type: 'button';
      fieldGroupName: string;
      label: string | null;
      link: { url: string } | null;
      style: ButtonProps['variant'];
      negativo: boolean;
      size: ButtonProps['size'];
      textColor: string;
    };

type TypeContent = {
  flexibleContent: TypeFlexibleContent[];
  imagenBase: TypeImageWordpress | null;
  srcset:
    | {
        anchura: string;
        imagen: TypeImageWordpress | null;
      }[]
    | null;
  align:
    | {
        align: string;
        mediaQuery: string;
      }[]
    | null;
  textAlign:
    | {
        textAlign: string;
        mediaQuery: string;
      }[]
    | null;
  justify:
    | {
        justify: string;
        mediaQuery: string;
      }[]
    | null;
  overlay: number | null;
  colorDeFondo: string | null;
  imageBackgroundLink: { url: string } | null;
};

interface TypeDoubleCardWithVideo {
  id: string;
  bloque: {
    column: [
      (
        | {
            contentType: 'video';
            video: TypeVideo;
          }
        | {
            contentType: 'content';
            content: TypeContent;
          }
      ),
      (
        | {
            contentType: 'video';
            video: TypeVideo;
          }
        | {
            contentType: 'content';
            content: TypeContent;
          }
      ),
    ];
  };
}

const DoubleCardWithVideo = ({ bloque, id }: TypeDoubleCardWithVideo) => {
  // Extract the type of the flexible content to later styling and rendering.
  for (const column of bloque.column) {
    if (column.contentType === 'content') {
      for (const flexibleContent of column.content.flexibleContent) {
        flexibleContent.type = flexibleContent.fieldGroupName
          .split('_')
          .at(-1)
          ?.toLocaleLowerCase() as TypeFlexibleContent['type'];
      }
    }
  }

  useInsertionEffect(() => {
    if (
      bloque.column[0].contentType === 'content' ||
      bloque.column[1].contentType === 'content'
    ) {
      const styleElement = document.createElement('style');

      for (let i = 0; i < bloque.column.length; ++i) {
        const column = bloque.column[i]!;

        if (column.contentType !== 'content') {
          continue;
        }

        const {
          content: { flexibleContent },
        } = column;

        for (let j = 0; j < flexibleContent.length; ++j) {
          const flexible = flexibleContent[j]!;

          if (flexible.type === 'title') {
            styleElement.textContent += `
                .double-card-with-video-${i}-${j}-${id}-title {
                  font-size: ${flexible.mobileSize || 36}px;
                  line-height: normal;
                }

                @media(min-width: 768px) {
                  .double-card-with-video-${i}-${j}-${id}-title {
                    font-size: ${flexible.desktopSize || 52}px;
                    line-height: normal;
                  }
                }
              `;
          }

          if (flexible.type === 'description') {
            styleElement.textContent += `
                .double-card-with-video-${i}-${j}-${id}-description {
                  font-size: ${flexible.mobileSize || 14}px;
                  line-height: ${(flexible.mobileSize || 14) + 4}px;
                }

                @media(min-width: 768px) {
                  .double-card-with-video-${i}-${j}-${id}-description {
                    font-size: ${flexible.desktopSize || 18}px;
                    line-height: ${(flexible.desktopSize || 18) + 4}px;
                  }
                }
              `;
          }
        }
      }

      document.head.appendChild(styleElement);

      return () => {
        document.head.removeChild(styleElement);
      };
    }
  }, []);

  return (
    <div className="u-wrapper relative flex flex-col py-4 md:py-8">
      <div className="grid grid-rows-2 sm:grid-cols-2 sm:grid-rows-none">
        {bloque.column.map((item, columnKey) => {
          if (item.contentType === 'video') {
            const { videoType } = item.video;

            if (videoType === 'wordpress') {
              const { wordpressVideo } = item.video;

              return (
                <Video
                  key={`native_video_${columnKey}`}
                  sources={wordpressVideo.map((video) => ({
                    src: video.video.mediaItemUrl,
                    type: video.mimeType,
                  }))}
                  autoplay={true}
                  className="h-full w-full bg-black"
                />
              );
            }

            if (videoType === 'youtube') {
              const { youtubeVideoId } = item.video;

              return (
                <div
                  key={`youtube_video_${columnKey}`}
                  className="pointer-events-none"
                >
                  <YoutubeVideo
                    className="h-full w-full bg-black"
                    videoId={youtubeVideoId}
                    onReady={(e) => e.target.playVideo()}
                    opts={{
                      playerVars: {
                        showinfo: 0,
                        modestbranding: 1,
                        rel: 0,
                        fs: 0,
                        autoplay: 0,
                        controls: 0,
                        disablekb: 1,
                        loop: 1,
                        mute: 1,
                        iv_load_policy: 3,
                        playsinline: 1,
                        enablejsapi: 1,
                        playlist: youtubeVideoId,
                      },
                    }}
                  />
                </div>
              );
            }
          }

          if (item.contentType === 'content') {
            const {
              colorDeFondo,
              flexibleContent,
              imageBackgroundLink,
              imagenBase,
              srcset,
              overlay,
            } = item.content;

            const { align, justify, textAlign } = containerTransformer({
              align: item?.content?.align || [],
              justify: item?.content?.justify || [],
              textAlign: item?.content?.textAlign || [],
            });

            return (
              <div
                style={{ backgroundColor: colorDeFondo || 'transparent' }}
                key={`content_${columnKey}`}
                className="relative flex p-6 md:p-8"
              >
                <ConditionalWrapper
                  condition={!!imageBackgroundLink?.url}
                  wrapper={(children) => (
                    <NextLink
                      href={removeBaseURL(imageBackgroundLink!.url || '/')}
                    >
                      <a>{children}</a>
                    </NextLink>
                  )}
                >
                  <>
                    {/* Image Background */}
                    {imagenBase && !srcset && (
                      <Image
                        src={imagenBase.sourceUrl}
                        alt={imagenBase.altText}
                        fill
                        draggable={false}
                        className="object-cover object-center"
                      />
                    )}

                    {imagenBase && srcset && (
                      <Picture
                        sources={
                          [
                            ...srcset.map((image) => {
                              if (!image.imagen || !image.imagen?.sourceUrl)
                                return null;

                              let media;

                              switch (image.anchura) {
                                case '4xl':
                                  media = 2560;
                                  break;
                                case '3xl':
                                  media = 1920;
                                  break;
                                case '2xl':
                                  media = 1440;
                                  break;
                                case 'xl':
                                  media = 1280;
                                  break;
                                case 'lg':
                                  media = 1024;
                                  break;
                                case 'md':
                                  media = 768;
                                  break;
                                case 'sm':
                                  media = 640;
                                  break;
                                case 'xs':
                                  media = 360;
                                  break;
                              }

                              return {
                                src: image.imagen?.sourceUrl,
                                width: image.imagen?.mediaDetails.width,
                                height: image.imagen?.mediaDetails.height,
                                media,
                              };
                            }),
                            ...(imagenBase && imagenBase.sourceUrl
                              ? [
                                  {
                                    src: imagenBase.sourceUrl,
                                    width: imagenBase.mediaDetails.width,
                                    height: imagenBase.mediaDetails.height,
                                  },
                                ]
                              : []),
                          ].filter(Boolean) as TypePictureProps['sources']
                        }
                        alt={imagenBase?.altText ?? ''}
                        classNameImg="object-cover object-center absolute inset-0 w-full h-full"
                      />
                    )}

                    {/* Overlay effect */}
                    {overlay && (
                      <div
                        className="absolute inset-0 bg-black"
                        style={{
                          opacity: overlay > 1 ? overlay / 100 : overlay,
                        }}
                      />
                    )}
                  </>
                </ConditionalWrapper>

                {/* Flexible Content */}
                {flexibleContent && flexibleContent.length > 0 && (
                  <div
                    className={`relative flex grow flex-col gap-4 ${justify} ${align} ${textAlign}`}
                  >
                    {flexibleContent.map((flexible, flexibleContentKey) => {
                      switch (flexible.type) {
                        case 'pretitle':
                          return (
                            <p
                              key={flexibleContentKey}
                              style={{
                                color: flexible?.color || '#262626',
                              }}
                              className="u-actions u-actions--link"
                            >
                              {flexible.pretitle}
                            </p>
                          );
                        case 'title':
                          return (
                            <Text
                              key={flexibleContentKey}
                              as={flexible.headingType}
                              style={{
                                color: flexible?.color || '#262626',
                              }}
                              className={`u-headline double-card-with-video-${columnKey}-${flexibleContentKey}-${id}-title`}
                            >
                              {flexible.title}
                            </Text>
                          );
                        case 'description':
                          return (
                            <p
                              key={flexibleContentKey}
                              style={{
                                color: flexible?.color || '#262626',
                              }}
                              className={`${flexible.allUppercase ? 'u-headline' : 'u-body'} double-card-with-video-${columnKey}-${flexibleContentKey}-${id}-description`}
                            >
                              {flexible.description}
                            </p>
                          );
                        case 'button':
                          const {
                            label,
                            link,
                            style,
                            negativo,
                            size,
                            textColor,
                          } = flexible;

                          if (!label || !link) {
                            return null;
                          }

                          return (
                            <NextLink
                              key={flexibleContentKey}
                              href={removeBaseURL(link.url)}
                              passHref
                            >
                              <Button
                                as="a"
                                variant={style}
                                negative={negativo}
                                size={size}
                                style={{ color: textColor }}
                              >
                                {label}
                              </Button>
                            </NextLink>
                          );
                        default:
                          return null;
                      }
                    })}
                  </div>
                )}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default DoubleCardWithVideo;
